import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['slider', 'left', 'right'];

  static classes = ['sliderLeft', 'sliderRight', 'buttonOn', 'buttonOff'];

  connect() {
    this.leftTarget.form.addEventListener('turbo:submit-end', this.update.bind(this));
    this.rightTarget.form.addEventListener('turbo:submit-end', this.update.bind(this));
  }

  disconnect() {
    this.leftTarget.form.removeEventListener('turbo:submit-end', this.update);
    this.rightTarget.form.removeEventListener('turbo:submit-end', this.update);
  }

  update(event) {
    const current = this.sliderTarget.classList.contains(this.sliderLeftClass) ? 'left' : 'right';
    const updated = event.detail?.formSubmission?.submitter?.dataset?.buttonSliderTarget;

    // ignore noop changes
    if (updated === current) return;

    this.leftTarget.classList.toggle(this.buttonOnClass);
    this.leftTarget.classList.toggle(this.buttonOffClass);
    this.rightTarget.classList.toggle(this.buttonOnClass);
    this.rightTarget.classList.toggle(this.buttonOffClass);

    this.sliderTarget.classList.toggle(this.sliderLeftClass);
    this.sliderTarget.classList.toggle(this.sliderRightClass);
  }
}
