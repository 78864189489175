import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['textarea'];

  static values = {
    maxHeight: { type: Number, default: 10 },
  };

  connect() {
    this.resize();
    this.textareaTarget.addEventListener('input', this.input.bind(this));
    this.textareaTarget.addEventListener('keydown', this.keydown.bind(this));
  }

  input() {
    // resize the textarea after input (e.g. when pasting text)
    this.resize();
  }

  keydown(event) {
    // ignore enter as it separately submits the form; allow shift+enter to insert a new line
    if (event.key === 'Enter' && !event.shiftKey) event.stopPropagation();
  }

  resize() {
    // reset height and overflow to allow for auto-growing
    this.textareaTarget.style.height = 'auto';
    this.textareaTarget.style.overflowY = 'hidden';

    // auto-grow the textarea until it reaches the max height or the line height is too large
    while (
      this.textareaTarget.scrollHeight > this.textareaTarget.offsetHeight &&
      this.textareaTarget.offsetHeight < this.maxHeightValue * parseFloat(getComputedStyle(this.textareaTarget).lineHeight)
    ) {
      this.textareaTarget.style.height = `${this.textareaTarget.offsetHeight + parseFloat(getComputedStyle(this.textareaTarget).lineHeight)}px`;
    }

    // if the line height is too large, set overflow to auto to allow for scrolling
    if (this.textareaTarget.offsetHeight >= this.maxHeightValue * parseFloat(getComputedStyle(this.textareaTarget).lineHeight)) {
      this.textareaTarget.style.overflowY = 'auto';
    }
  }
}
