import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['container', 'more'];

  static values = {
    buffer: { type: Number, default: 25 },
  };

  connect() {
    this.isAtBottom = true;
    this.scrollToBottom();

    this.containerTarget.addEventListener('scroll', this.handleScroll);
    document.addEventListener('turbo:before-stream-render', this.handleStreamRender);
  }

  disconnect() {
    this.containerTarget.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('turbo:before-stream-render', this.handleStreamRender);
  }

  handleStreamRender = () => {
    if (this.isAtBottom) {
      this.scrollToBottom();
    } else {
      // store the previous scroll height and position
      const prevScrollHeight = this.containerTarget.scrollHeight;
      const prevScrollTop = this.containerTarget.scrollTop;

      // wait for the DOM to update
      requestAnimationFrame(() => {
        // calculate the height difference after new content
        const heightDifference = this.containerTarget.scrollHeight - prevScrollHeight;

        // adjust scroll position to maintain relative position
        this.containerTarget.scrollTop = prevScrollTop + heightDifference;
      });
    }
  };

  handleScroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = this.containerTarget;
    this.isAtBottom = scrollHeight - (scrollTop + clientHeight) < this.bufferValue;
  };

  scrollToBottom() {
    this.containerTarget.scrollTop = this.containerTarget.scrollHeight;
    setTimeout(() => (this.containerTarget.scrollTop = this.containerTarget.scrollHeight), 100);
  }

  moreTargetConnected() {
    useIntersection(this, { element: this.moreTarget, threshold: 0.1 });
  }

  appear(entry, observer) {
    observer.unobserve(entry.target);
    get(this.moreTarget.href, { responseKind: 'turbo-stream' }).then(() => {
      this.moreTarget.remove();
    });
  }
}
