import consumer from '../channels/consumer';
import { ApplicationController, useDebounce, useResize } from 'stimulus-use';

export default class extends ApplicationController {
  static debounces = ['resize'];

  static values = {
    projectId: String,
    actorId: String,
  };

  static targets = ['iframe', 'url', 'cursor', 'canvas'];

  static classes = ['navExpanded'];

  connect() {
    useDebounce(this);
    useResize(this);

    if (this.hasProjectIdValue && this.hasActorIdValue) {
      this.messageHandler = this.handleMessage.bind(this);
      window.addEventListener('message', this.messageHandler);

      this.channel = consumer.subscriptions.create(
        { channel: 'ActorStatusChannel', project_id: this.projectIdValue },
        { received: this.handleStatus.bind(this) },
      );
    }

    this.resize();
  }

  disconnect() {
    window.removeEventListener('message', this.messageHandler);
    this.channel?.unsubscribe();
  }

  resize() {
    if (!this.element.parentElement) return;

    this.element.style.display = 'none';
    const rect = this.element.parentElement.getBoundingClientRect();

    this.padding = [0, 0, 0, 0];
    this.padding[0] = parseFloat(window.getComputedStyle(this.element.parentElement, null).getPropertyValue('padding-top'));
    this.padding[1] = parseFloat(window.getComputedStyle(this.element.parentElement, null).getPropertyValue('padding-right'));
    this.padding[2] = parseFloat(window.getComputedStyle(this.element.parentElement, null).getPropertyValue('padding-bottom'));
    this.padding[3] = parseFloat(window.getComputedStyle(this.element.parentElement, null).getPropertyValue('padding-left'));

    this.ratio = Math.max(0.1, Math.min(1.0, (rect.width - this.padding[1] - this.padding[3]) / 1280));
    this.element.style.display = null;

    if (this.hasIframeTarget) this.iframeTarget.style.transform = `scale(${this.ratio})`;
    if (this.hasCanvasTarget) this.canvasTarget.style.transform = `scale(${this.ratio})`;
  }

  handleMessage(event) {
    if (event.data === 'browserbase-disconnected') Turbo.visit(window.location.href, { frame: 'screenshare_drawer' });
  }

  toggle({ detail: { kind, target } }) {
    if (kind === 'content' && target.id === 'screenshare_drawer') {
      const nav = document.querySelector('[data-screenshare-remote-element="nav"]');
      const container = document.querySelector('[data-screenshare-remote-element="container"]');

      // TODO: read from computed style
      if (target.classList.contains('hidden')) {
        if (nav && this.hasNavExpandedClass) nav.classList.remove(...this.navExpandedClasses);
        if (container) container.style.paddingTop = null;
      } else {
        if (nav && this.hasNavExpandedClass) nav.classList.add(...this.navExpandedClasses);
        if (container) container.style.paddingTop = `${nav.getBoundingClientRect().height + 25}px`;
      }
    }
  }

  handleStatus({ actor_id, current_url, current_cursor_position }) {
    if (actor_id !== this.actorIdValue) return;

    if (!this.hasIframeTarget) {
      Turbo.visit(window.location.href, { frame: 'screenshare_drawer' });
      return;
    }

    if (this.hasUrlTarget) {
      this.urlTarget.innerText = current_url;
    }

    if (this.hasCursorTarget) {
      const [left, top] = current_cursor_position.split(',').map(Number);
      this.cursorTarget.style.left = `${left}px`;
      this.cursorTarget.style.top = `${top}px`;
    }
  }
}
