import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'textarea', 'submit', 'container'];

  connect() {
    this.textareaTarget.focus();
    this.textareaTarget.addEventListener('keydown', this.keydown.bind(this));
    this.formTarget.addEventListener('turbo:submit-start', this.beforeSubmit.bind(this));
    this.formTarget.addEventListener('turbo:submit-end', this.afterSubmit.bind(this));
  }

  keydown(event) {
    // request form submit on enter key press, unless shift+enter was pressed
    if (event.key === 'Enter' && !event.shiftKey) {
      this.formTarget.requestSubmit();
      event.preventDefault();
    }
  }

  beforeSubmit() {
    // disable form elements during submission
    this.textareaTarget.disabled = true;
    this.submitTarget.disabled = true;
  }

  afterSubmit(event) {
    // re-enable form elements after submission
    this.textareaTarget.disabled = false;
    this.submitTarget.disabled = false;

    if (event.detail.success) {
      // clear the input
      this.textareaTarget.value = '';

      // dispatch input event to trigger resize listener
      this.textareaTarget.dispatchEvent(new Event('input', { bubbles: true }));
    } else {
      alert('Failed to send message');
    }

    // refocus the input
    this.textareaTarget.focus();

    // scroll message container to bottom
    if (this.hasContainerTarget) {
      this.containerTarget.scrollTop = this.containerTarget.scrollHeight;
      setTimeout(() => (this.containerTarget.scrollTop = this.containerTarget.scrollHeight), 100);
    }
  }
}
