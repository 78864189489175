import React, { memo } from 'react';
import { Handle, Position, useStore } from '@xyflow/react';

const MindMapNode = memo(({ id, data }) => {
  // get connected edges for this node
  const getConnectedEdges = useStore((store) => {
    const edges = store.edges || [];
    return edges.filter((edge) => edge.source === id || edge.target === id);
  });

  // check if node has incoming or outgoing connections
  const hasIncoming = getConnectedEdges.some((edge) => edge.target === id);
  const hasOutgoing = getConnectedEdges.some((edge) => edge.source === id);

  return (
    <div className="rounded-lg border border-gray-200 bg-white px-4 py-2 shadow-sm">
      {hasIncoming && <Handle type="target" position={Position.Top} />}

      {data.detailed ? (
        <div className="flex flex-col items-center gap-2 whitespace-pre-wrap">
          {Object.entries(data).map(([key, value]) => (
            <div key={key} className="flex flex-col items-center text-xs">
              <div className="text-gray-500">{key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}</div>
              <div className="font-medium text-gray-900">{value}</div>
            </div>
          ))}
        </div>
      ) : (
        <div>{data.name || data.description}</div>
      )}

      {hasOutgoing && <Handle type="source" position={Position.Bottom} />}
    </div>
  );
});

export default MindMapNode;
