import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="toggler"
export default class extends Controller {
  static targets = ['switch', 'content'];

  static classes = ['switchOn', 'switchOff', 'contentOn', 'contentOff'];

  static values = {
    switchInitialState: { type: Boolean, default: false },
    contentInitialState: { type: Boolean, default: false },
    remoteSwitch: { type: String, default: null },
    remoteContent: { type: String, default: null },
  };

  connect() {
    // allow overriding targets with elements outside the controller
    this.switchTarget;
    if (this.hasSwitchTarget) this._switchTarget = this.switchTarget;
    else if (this.remoteSwitchValue) this._switchTarget = document.querySelector(this.remoteSwitchValue);

    if (this._switchTarget) {
      if (this.switchInitialStateValue) {
        if (this.hasSwitchOnClass) this._switchTarget.classList.add(...this.switchOnClasses);
        if (this.hasSwitchOffClass) this._switchTarget.classList.remove(...this.switchOffClasses);
      } else {
        if (this.hasSwitchOnClass) this._switchTarget.classList.remove(...this.switchOnClasses);
        if (this.hasSwitchOffClass) this._switchTarget.classList.add(...this.switchOffClasses);
      }
    }

    this._contentTarget;
    if (this.hasContentTarget) this._contentTarget = this.contentTarget;
    else if (this.remoteContentValue) this._contentTarget = document.querySelector(this.remoteContentValue);

    if (this._contentTarget) {
      if (this.contentInitialStateValue) {
        if (this.hasContentOnClass) this._contentTarget.classList.add(...this.contentOnClasses);
        if (this.hasContentOffClass) this._contentTarget.classList.remove(...this.contentOffClasses);
      } else {
        if (this.hasContentOnClass) this._contentTarget.classList.remove(...this.contentOnClasses);
        if (this.hasContentOffClass) this._contentTarget.classList.add(...this.contentOffClasses);
      }
    }
  }

  toggleSwitch() {
    if (!this._switchTarget) return;

    if (this.hasSwitchOnClass) this.switchOnClasses.forEach((name) => this._switchTarget.classList.toggle(name));
    if (this.hasSwitchOffClass) this.switchOffClasses.forEach((name) => this._switchTarget.classList.toggle(name));

    this.dispatch('toggled', { detail: { kind: 'switch', target: this._switchTarget } });
  }

  toggleContent() {
    if (!this._contentTarget) return;

    if (this.hasContentOnClass) this.contentOnClasses.forEach((name) => this._contentTarget.classList.toggle(name));
    if (this.hasContentOffClass) this.contentOffClasses.forEach((name) => this._contentTarget.classList.toggle(name));

    this.dispatch('toggled', { detail: { kind: 'content', target: this._contentTarget } });
  }

  toggle() {
    this.toggleSwitch();
    this.toggleContent();
  }

  handleClickOutside(event) {
    // Don't do anything if the content is already hidden (i.e. if any of the contentOffClasses are present)
    if ([...this._contentTarget.classList].some((name) => this.contentOffClasses.includes(name))) return;

    // Check if the click was inside the content
    if (this._contentTarget.contains(event.target)) return;

    // Prevent clicks on any elements when dropdown is open
    event.preventDefault();
    event.stopPropagation();

    // Check if the click was outside both the switch and content
    if (!this._switchTarget.contains(event.target) && !this._contentTarget.contains(event.target)) {
      this.toggle();
    }
  }
}
