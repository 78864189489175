import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['source', 'button'];

  static classes = ['copied'];

  static values = {
    delay: { type: Number, default: 250 },
  };

  copy(event) {
    event.preventDefault();

    navigator.clipboard.writeText(this.sourceTarget.value || this.sourceTarget.textContent);

    if (this.hasButtonTarget && this.hasCopiedClass) {
      this.buttonTarget.classList.add(this.copiedClass);

      setTimeout(() => {
        try {
          this.buttonTarget.classList.remove(this.copiedClass);
        } catch (e) {}
      }, this.delayValue);
    }
  }
}
