import { Controller } from '@hotwired/stimulus';
import React from 'react';
import { createRoot } from 'react-dom/client';

import MindMap from '../components/MindMap';

import '@xyflow/react/dist/style.css';

export default class extends Controller {
  static targets = ['container', 'toggle', 'chat', 'icon'];

  static values = {
    channel: String,
    nodes: Array,
    edges: Array,
    detailed: Boolean,
  };

  connect() {
    const container = this.containerTarget;
    container.style.width = '100%';
    container.style.height = '100%';

    createRoot(container).render(
      <MindMap channel={this.channelValue} initialNodes={this.nodesValue} initialEdges={this.edgesValue} detailed={this.detailedValue} />,
    );

    if (this.hasChatTarget && this.hasToggleTarget) {
      this.toggleTarget.addEventListener('click', this.toggle_chat.bind(this));
    }

    this.expand_icon = this.iconTargets.find((icon) => icon.dataset.icon === 'expand');
    this.collapse_icon = this.iconTargets.find((icon) => icon.dataset.icon === 'collapse');
  }

  toggle_chat() {
    this.expand_icon.style.display = this.expand_icon.style.display === 'none' ? null : 'none';
    this.collapse_icon.style.display = this.collapse_icon.style.display === 'none' ? null : 'none';

    if (this.expand_icon.style.display === 'none') {
      this.chatTarget.style.display = 'none';
      this.containerTarget.parentElement.style.width = '100%';
    } else {
      this.chatTarget.style.display = null;
      this.containerTarget.parentElement.style.width = null;
    }
  }
}
