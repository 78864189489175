import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:render', () => this.deobfuscate_cloudflare_email_protection());
    document.addEventListener('turbo:frame-render', () => this.deobfuscate_cloudflare_email_protection());
  }

  deobfuscate_cloudflare_email_protection() {
    // inject cloudflare's email protection script (except on dev)
    if (window.location.hostname.includes('.test')) return;

    const script = document.createElement('script');
    script.src = '/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js';
    document.head.appendChild(script);
  }

  disconnect() {
    document.removeEventListener('turbo:render', () => this.deobfuscate_cloudflare_email_protection());
    document.removeEventListener('turbo:frame-render', () => this.deobfuscate_cloudflare_email_protection());
  }
}
