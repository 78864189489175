import { ApplicationController, useDebounce } from 'stimulus-use';

export default class extends ApplicationController {
  static debounces = ['submit'];

  static targets = ['input'];

  connect() {
    useDebounce(this);

    this.inputTarget.addEventListener('input', () => this.submit());
  }

  submit() {
    this.element.requestSubmit();
  }
}
